@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.hide-scroll {
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.hide-scroll::-webkit-scrollbar { 
  width: 0;
  height: 0;
  background: transparent;
}

.preview_description{
  word-wrap: break-word;
  overflow: hidden;
  min-height: 4.8em;
  max-height: 4.8em;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.preview_message{
  word-wrap: break-word;
  overflow: hidden;
  min-height: 3.6em;
  max-height: 3.6em;
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-decoration{
  text-decoration: none;
}

.no-decoration:hover{
  text-decoration: none ;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
